/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React, { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Button, Grid, Go } from "~components";
import { useInterval } from "~hooks";
import { MEDIA_QUERIES } from "~utils/helpers";

import { ReactComponent as PlantPoweredSticker } from "~assets/stickers/sticker-plant-powered.svg";

import vanillaChocPop from "~assets/images/home-plant-based-vanilla-choc-pop.png";
import caramelMacadamia from "~assets/images/home-sugar-free-caramel-macadamia-ice-cream.png";
import espressoChocFudge from "~assets/images/home-sugar-free-espresso-choc-fudge-ice-cream.png";
import raspberryRibbon from "~assets/images/home-sugar-free-raspberry-ribbon-ice-cream.png";
import saltedCaramel from "~assets/images/home-sugar-free-salted-caramel-ice-cream.png";

/** --------------------------------------------------------------------------
 * @css
 */

const Container = styled.section`
  transition: 0.15s var(--cubic-easing) background;

  width: 100%;
  position: relative;
  z-index: 20;
  display: block;
  background: #ecc3b2;

  ${MEDIA_QUERIES.desktop} {
    height: calc(100vh - 4rem);
    min-height: 720px;
    /* cursor: pointer; */
  }
`;

//

const ContentContainer = styled.div`
  grid-column: -1 / 1;
  position: relative;
  z-index: 10;

  ${MEDIA_QUERIES.desktop} {
    grid-column: span 5 / span 5;
    height: 100%;
    bottom: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const Heading = styled.div`
  width: 100%;
  display: block;
  user-select: none;
  text-align: center;
  color: var(--color-white);

  ${MEDIA_QUERIES.desktop} {
    margin-top: 0;
    text-align: left;
  }
`;

//

const TubContainer = styled.div`
  grid-column: -1 / 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;

  ${MEDIA_QUERIES.desktop} {
    grid-column: span 7 / span 7;
    height: 100%;
    padding: 0;
  }
`;

const TubFigure = styled.figure`
  transition: 0.15s var(--cubic-easing) transform;
  cursor: pointer;
  position: relative;
  width: 90%;

  ${MEDIA_QUERIES.desktop} {
    width: 85%;
  }
`;

const TubImg = styled.img`
  width: 100%;
  user-select: none;

  ${MEDIA_QUERIES.desktop} {
    //
  }
`;

/** --------------------------------------------------------------------------
 * @component TODO: doc
 */
const TubCarousel = () => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { isMobile, isDesktop, isTablet } = useBreakpoint();
  const isTabletOnly = isTablet && !isDesktop && !isMobile;

  const ref = useRef();

  const [activeTubIndex, setActiveTubIndex] = useState(0);
  const [skipping, setSkipping] = useState(false);
  const [transitioning, setTransitioning] = useState(false);

  const [pos, setPos] = useState({
    x: 0,
    y: 0
  });

  // ---------------------------------------------------------------------------
  // variables

  const tubs = [
    {
      heading: `SUGAR FREE, DAIRY FREE, CARE FREE`,
      image: caramelMacadamia,
      background: `var(--color-macadamia-yellow)`,
      buttonText: `GIMME!`,
      svg: {
        primaryColor: `var(--color-macadamia-green)`, // stroke and text color
        secondaryColor: `var(--color-macadamia-purple)` // background color
      }
    },
    {
      heading: `SUGAR FREE, DAIRY FREE, CARE FREE`,
      image: vanillaChocPop,
      background: `var(--color-vanilla-green)`,
      buttonText: `TAKE A PEEK!`,
      svg: {
        primaryColor: `var(--color-vanilla-pink-light)`, // stroke and text color
        secondaryColor: `var(--color-vanilla-brown)` // background color
      }
    },
    {
      heading: `SUGAR FREE, DAIRY FREE, CARE FREE`,
      image: espressoChocFudge,
      background: `var(--color-espresso-purple)`,
      buttonText: `SAY HELLO`,
      svg: {
        primaryColor: `var(--color-espresso-choc)`, // stroke and text color
        secondaryColor: `var(--color-espresso-red)` // background color
      }
    },
    {
      heading: `SUGAR FREE, DAIRY FREE, CARE FREE`,
      image: saltedCaramel,
      background: `var(--color-salted-caramel)`,
      buttonText: `DIG IN`,
      svg: {
        primaryColor: `var(--color-salted-caramel-yellow)`, // stroke and text color
        secondaryColor: `var(--color-salted-caramel-pink)` // background color
      }
    },
    {
      heading: `SUGAR FREE, DAIRY FREE, CARE FREE`,
      image: raspberryRibbon,
      background: `var(--color-raspberry-vegan-green)`,
      buttonText: `SHOW ME`,
      svg: {
        primaryColor: `var(--color-raspberry-vegan-pink)`, // stroke and text color
        secondaryColor: `var(--color-raspberry-light)` // background color
      }
    }
  ];

  // ---------------------------------------------------------------------------
  // variables

  const AUTO_TRANSITION_SPEED = 3000;

  // ---------------------------------------------------------------------------
  // methods

  const newTub = () => {
    setActiveTubIndex(
      activeTubIndex + 1 < tubs?.length ? activeTubIndex + 1 : 0
    );
  };

  const relativeCursorHandler = (e) => {
    if (!ref?.current || !isDesktop) return;

    const { left, top } = ref.current.parentNode.getBoundingClientRect();

    setPos({
      x: e.clientX - left,
      y: e.clientY - top
    });
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useInterval(() => {
    if (skipping) {
      setSkipping(false);
      return;
    }

    newTub();
  }, AUTO_TRANSITION_SPEED);

  useEffect(() => {
    setTransitioning(true);
  }, [activeTubIndex]);

  useEffect(() => {
    if (!transitioning) {
      return () => {};
    }

    const timeout = setTimeout(() => {
      setTransitioning(false);
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [transitioning]);

  // ---------------------------------------------------------------------------
  // render

  const activeTub = tubs?.[activeTubIndex];

  return (
    <Container
      role="presentation"
      ref={ref}
      onMouseMove={relativeCursorHandler}
      css={css`
        background: ${activeTub?.background || `var(--color-cookies-cream)`};
      `}
    >
      <Grid
        _css={css`
          height: 100%;
          padding-top: 1rem;
          padding-bottom: 1rem;

          ${MEDIA_QUERIES.desktop} {
            padding-top: 0;
            padding-bottom: 0;
          }
        `}
      >
        <ContentContainer>
          <Heading>
            <h2 className={isTabletOnly ? `h1` : `d3`}>{activeTub.heading}</h2>
          </Heading>

          <AnchorLink
            css={css`
              display: none;

              ${MEDIA_QUERIES.tablet} {
                display: block;
              }
            `}
            to="/products#DairyFree-section"
          >
            <Button
              _css={css`
                width: 100%;
                margin-top: 2rem;
                margin-left: 0;

                ${MEDIA_QUERIES.tablet} {
                  width: 200px;
                  margin: 2rem auto;
                }

                ${MEDIA_QUERIES.desktop} {
                  width: auto;
                  margin: 3rem 0;
                }
              `}
              text={activeTub.buttonText}
            />
          </AnchorLink>
        </ContentContainer>

        <TubContainer>
          <TubFigure
            onClick={() => {
              setSkipping(true);
              newTub();
            }}
          >
            <TubImg
              css={css`
                transform: scale(${transitioning ? `0.925` : `1`});

                ${MEDIA_QUERIES.desktop} {
                  transform: translate3d(
                      ${parseInt(pos.x / 30)}px,
                      ${parseInt(pos.y / 30) - 40}px,
                      0
                    )
                    scale(${transitioning ? `0.925` : `1`});
                }
              `}
              src={activeTub?.image}
              alt={activeTub?.heading}
            />

            <figure
              css={css`
                position: absolute;
                bottom: 0;
                right: 0;

                svg {
                  transform: rotate(15deg);
                  width: 120px;

                  ${MEDIA_QUERIES.tablet} {
                    width: 200px;

                    // width: 186px;
                    // height: 186px;
                  }

                  .sticker-plant-powered_svg__svg-plant-powered-outer {
                    stroke: ${activeTub?.svg?.primaryColor};
                    fill: ${activeTub?.svg?.secondaryColor};
                  }

                  .sticker-plant-powered_svg__svg-plant-powered-inner {
                    fill: ${activeTub?.svg?.primaryColor};
                  }
                }

                ${MEDIA_QUERIES.desktop} {
                  // display: none;
                  bottom: 5%;
                  right: -8%;
                }
              `}
            >
              <PlantPoweredSticker />
            </figure>
          </TubFigure>
        </TubContainer>

        <AnchorLink
          css={css`
            grid-column: 1 / -1;

            ${MEDIA_QUERIES.tablet} {
              display: none;
            }
          `}
          to="/products#DairyFree-section"
        >
          <Button
            _css={css`
              width: 100%;
              margin-left: 0;

              ${MEDIA_QUERIES.tablet} {
                width: 200px;
                margin: 2rem auto;
              }

              ${MEDIA_QUERIES.desktop} {
                width: auto;
                margin: 3rem 0;
              }
            `}
            text={activeTub.buttonText}
          />
        </AnchorLink>
      </Grid>
    </Container>
  );
};

export default TubCarousel;
